<template>
  <div class="px-6 pt-6 pb-16">
    <MunicipalityPathGuard>
      <div class="max-w-7xl mx-auto">
        <div class="w-full">
          <p class="max-w-xl lg:max-w-none text-black text-large font-normal">
            Andmik-us on tehtud liidestus XML failide lugemiseks. Võimalik
            laadida eelarveandmiku ja investeeringu objektide XML faile.
          </p>
        </div>
        <div class="flex flex-wrap space-y-10">
          <div class="w-full">
            <p class="mt-8 text-black text-large font-normal">
              <span class="font-bold">Koondvaade, Tulud ja Kulud</span> XML
              formaat on järgmine:
            </p>
            <img
              src="../assets/images/juhend-img.png"
              class="mt-2 shadow-small rounded-md lg:max-w-lg"
              alt=""
            />
          </div>

          <div
            class="
              w-full
              flex flex-wrap
              lg:flex-nowrap
              space-y-10
              lg:space-y-0 lg:space-x-8
            "
          >
            <div class="w-full lg:w-1/2">
              <p class="text-black text-large font-normal">
                Antud formaadi saab näiteks
                <span class="font-bold">PMEN programmist</span>, kui avada
                <span
                  class="
                    font-bold
                    text-primary-orange
                    bg-primary-light-orange
                    inline-flex
                    items-center
                  "
                >Trükk
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 mx-0.5 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  Eelarveandmik</span
                >
              </p>
              <img
                src="../assets/images/juhend-img-2.png"
                class="mt-2 shadow-small rounded-md"
                alt=""
              />
            </div>

            <div class="w-full lg:w-1/2">
              <p class="text-black text-large font-normal">
                Edasi klikkida
                <span
                  class="
                    py-0.5
                    font-bold
                    text-primary-orange
                    bg-primary-light-orange
                  "
                >"Faili"</span
                >
                ja salvestamisel valida
                <span
                  class="
                    py-0.5
                    font-bold
                    text-primary-orange
                    bg-primary-light-orange
                  "
                >Salvestustüübiks XML</span
                >
                ning salvestada fail.
              </p>
              <img
                src="../assets/images/juhend-img-3.png"
                class="mt-2 shadow-small rounded-md"
                alt=""
              />
            </div>
          </div>

          <div class="w-full">
            <p class="text-black text-large font-normal">
              <span class="font-bold">Investeeringute</span> XML formaat on
              järgmine:
            </p>
            <div class="flex flex-wrap lg:flex-nowrap lg:space-x-6">
              <img
                src="../assets/images/juhend-img-4.png"
                class="mt-2 shadow-small rounded-md"
                alt=""
              />
              <p
                class="
                  mt-4
                  lg:mt-0
                  max-w-xl
                  lg:max-w-md
                  text-black text-large
                  font-normal
                "
              >
                Investeeringute XML faili saamiseks tuleks seadistada
                investeeringu objektidele külge kulu_obj nr. ja salvestada fail.
              </p>
            </div>
          </div>

          <div class="py-10 w-full border-t border-b">
            <p class="max-w-3xl text-black text-large font-normal">
              Kui teil faili laadimine ebaõnnestub või kasutate teist
              raamatupidamistarkvara, siis palun saatke XML fail meile
              aadressile
              <span class="font-bold text-lg text-primary-teal"
              >info@andmik.ee</span
              >,<span class="font-bold">
                et saaksime luua Andmik-usse ühenduse antud failile.</span
            >
            </p>
          </div>

          <div class="w-full border-b pb-12">

            <p class="max-w-3xl text-black text-large font-normal">
              Valla kodulehele eelarve täitmise linkimiseks võib kasutada
              järgmisi pilte ja linki:
            </p>
            <div class="flex flex-wrap lg:flex-nowrap lg:space-x-6">
              <div class="w-full lg:w-1/2 order-2 lg:order-1">
                <div class="mt-4 space-y-8">
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine.jpg')
                      "
                      download="eelarvetaitmine.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-orange.jpg')
                      "
                      download="eelarvetaitmine-orange.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-orange.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-teal.jpg')
                      "
                      download="eelarvetaitmine-teal.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-teal.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 order-1 lg:order-2">
                <div class="mt-4 space-y-8">
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-small.jpg')
                      "
                      download="eelarvetaitmine-small.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-small.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-small-2.jpg')
                      "
                      download="eelarvetaitmine-small-2.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-small-2.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-small-3.jpg')
                      "
                      download="eelarvetaitmine-small-3.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-small-3.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                   <div>
                    <a
                      :href="
                        require('../assets/images/brand/Eelarvetäitmine-small-0.jpg')
                      "
                      download="eelarvetaitmine-small-0.jpg"
                      class="cursor-pointer group"
                    >
                      <img
                        src="../assets/images/brand/Eelarvetäitmine-small-0.jpg"
                        alt="andmik"
                      />
                      <div
                        class="
                          inline-flex
                          mt-1
                          cursor-pointer
                          underline
                          text-base
                          font-semibold
                          text-primary-teal
                          group-hover:text-indigo-600
                          transition
                          duration-300
                          ease-in-out
                        "
                      >
                        Lae pilt alla
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactForm class="mt-12">
          Probleemide korral võtke ühendust
        </ContactForm>
      </div>
    </MunicipalityPathGuard>
  </div>
</template>

<script lang="ts">
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";
import ContactForm from "@/components/common/utils/contact/ContactForm.vue";
import { computed, defineComponent } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

export default defineComponent({
  components: { ContactForm, MunicipalityPathGuard },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipality = computed(() => municipalityState.municipality);
    const slug = computed(() => municipality.value?.uid);
    const municipalityLink = computed(() => `https://www.andmik.ee/${slug.value}`);
    return { municipalityLink };
  }
});
</script>

<style lang="scss" scoped></style>
