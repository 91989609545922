
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";
import ContactForm from "@/components/common/utils/contact/ContactForm.vue";
import { computed, defineComponent } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

export default defineComponent({
  components: { ContactForm, MunicipalityPathGuard },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipality = computed(() => municipalityState.municipality);
    const slug = computed(() => municipality.value?.uid);
    const municipalityLink = computed(() => `https://www.andmik.ee/${slug.value}`);
    return { municipalityLink };
  }
});
